import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/home/home2.JPG";
import Tilt from "react-parallax-tilt";
import ListGroup from 'react-bootstrap/ListGroup';


function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">

            <div style={{ textAlign: "left" }}>
              <h2 style={{ color: "red" }}>My Service</h2>

              <ListGroup as="ol" numbered>
                <ListGroup.Item as="li" variant="dark">Short Film</ListGroup.Item>
                <ListGroup.Item as="li" variant="dark">Event</ListGroup.Item>
                <ListGroup.Item as="li" variant="dark">Birthday</ListGroup.Item>
                <ListGroup.Item as="li" variant="dark">Music Video</ListGroup.Item>
                <ListGroup.Item as="li" variant="dark">TVC</ListGroup.Item>
                <ListGroup.Item as="li" variant="dark">Sports</ListGroup.Item>
                <ListGroup.Item as="li" variant="dark">Documentary</ListGroup.Item>
                <ListGroup.Item as="li" variant="dark">Interview</ListGroup.Item>
                <ListGroup.Item as="li" variant="dark">Pre-Screening</ListGroup.Item>
                <ListGroup.Item as="li" variant="dark">Graduation Parties</ListGroup.Item>
              </ListGroup>

            </div>

          </Col>
          <Col md={4} className="myAvtar">
            {/* <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt> */}
            <img src={myImg} className="img-fluid" alt="avatar" />

          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default Home2;
