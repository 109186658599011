import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

import work1 from "../../Assets/work/work1.jpg";
import work2 from "../../Assets/work/work2.jpg";
import work3 from "../../Assets/work/work3.jpg";

// import project3 from "../../Assets/portfolio/ecommerce-website.jpg";

function Work() {
  return (
    <Container fluid className="project-section">
      {/* <Particle /> */}
      <Container>
        {/* <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p> */}
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col style={{ paddingBottom: 20 }}>
            <h2 style={{ color: "red" }}>Pre-Screening</h2>
            <h5 style={{ color: "white" }}>Deadpool & Wolverine</h5>
            <img
              src={work1}
              alt="home pic"
              className="img-fluid"
              style={{ maxHeight: "450px" }}
              rounded
            />
          </Col>

        </Row>

        <Row>
        <Col style={{ paddingBottom: 20 }}>
            {/* <h4 style={{ color: "red" }}>Escape</h4> */}
            <h5 style={{ color: "white" }}>Escape</h5>
            <img
              src={work3}
              alt="home pic"
              className="img-fluid"
              style={{ maxHeight: "450px" }}
              rounded
            />
          </Col>
        </Row>

        <Row>
          <Col style={{ paddingBottom: 20 }}>
            <h2 style={{ color: "red" }}>Event</h2>
            <h5 style={{ color: "white" }}>Hong Kong Film Gala Presentation</h5>

            <img
              src={work2}
              alt="home pic"
              className="img-fluid"
              style={{ maxHeight: "450px" }}
              rounded
            />
          </Col>
        </Row>

      
    </Container>
    </Container >
  );
}

export default Work;
