import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import Toolstack from "./Toolstack";
import ListGroup from 'react-bootstrap/ListGroup';

import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillYoutube
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";



function About() {
  return (
    <Container fluid className="about-section">
      {/* <Particle /> */}
      <Container>

        <Row>
          <Col>

            <h1 style={{ color: "red" }}>Introduce myself</h1><br />

            Hello, my name is Ton. I have many skills that can help make your dreams come true with my experience of over a decade, including techniques, standard rules, and unlimited speed, ensuring that every project is created. Built with clear reliability, quality, standards and high creativity.

            <br /><br /><br/><br/>
            <h1 style={{ color: "red" }}>My Skills</h1>

            <p>
              Produce, direct, record, and edit creative video content that reflects creativity and quality, including social media, short stories, storytelling, branding, long forms, news content, and more.<br /><br />
              Collaborate with marketing teams to create creative video ideas that align with marketing and audio strategies.<br /><br />
              Runs a variety of video equipment, including drone cameras and flash to produce high-quality video.<br /><br />
              Edit and assemble the latest visual and audio material, ensuring the consistency and harmony of the story.<br /><br />
              Manage post-production processes, including coloring, sound editing, and graphic integration.<br /><br />
              Get the latest news with the latest technical trends and technologies in video production and editing.<br /><br />
              Work on multiple projects simultaneously, adhering to strict deadlines while maintaining both quantity and quality.<br /><br />
            </p>

          </Col>
        </Row>

        <Row>
          <Col md={12} className="home-about-social">
            <p>
              Feel free to connect me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/directedbyton"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/directedbyton"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://youtube.com/@directedbyton"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillYoutube />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="https://www.tiktok.com/@directedbyton"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaTiktok />
                </a>
              </li>

             
            </ul>

          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default About;
